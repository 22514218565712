import { adminClient } from "@api/admin/AdminClient";
import { Button } from "@material-ui/core";
import FileDownloadIcon from "@material-ui/icons/CloudDownload";
import { useState } from "react";
import { CSVDownload } from "react-csv";

export default function PurchaseLogDownloadCsvComponent() {
  const [purchaseLogCsv, setPurchaseLogCsv] = useState<string>();
  const [isCsvDownloading, setIsCsvDownloading] = useState<boolean>(false);

  const handleCsvDownloadingPlus = async () => {
    try {
      setIsCsvDownloading(true);
      setPurchaseLogCsv(undefined);

      var purchaseLogCsv =
        await adminClient().adminPurchaseLogPlusSubscriptionsCsvGet();

      setPurchaseLogCsv(purchaseLogCsv.fileContents);
    } finally {
      setIsCsvDownloading(false);
    }
  };

  const handleCsvDownloadingNonPlus = async () => {
    try {
      setIsCsvDownloading(true);
      setPurchaseLogCsv(undefined);

      var purchaseLogCsv =
        await adminClient().adminPurchaseLogNonPlusSubscriptionsCsvGet();
      setPurchaseLogCsv(purchaseLogCsv.fileContents);
    } finally {
      setIsCsvDownloading(false);
    }
  };

  return (
    <>
      {purchaseLogCsv && <CSVDownload data={purchaseLogCsv} target="_self" />}
      <Button
        variant="contained"
        style={{ marginLeft: 16 }}
        color="primary"
        disabled={isCsvDownloading}
        onClick={async () => await handleCsvDownloadingPlus()}
      >
        <span>
          <FileDownloadIcon />
          <br /> Download plus savings user
        </span>
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: 16 }}
        color="primary"
        disabled={isCsvDownloading}
        onClick={async () => await handleCsvDownloadingNonPlus()}
      >
        <span>
          <FileDownloadIcon />
          <br /> Download non plus possible savings
        </span>
      </Button>
    </>
  );
}
